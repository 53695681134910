.app {
	width: 100vw;
	height: 100vh;
}

.hidden > img {
	display: none;
}

.viewer-player {
	background-color: transparent;
}

img.viewer-move {
	border-radius: 20px;
}
